import * as React from 'react';
import { Component } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Rutas } from './app/rutas';


import style from './app/style.scss';
import { Proveedor } from './app/contexto';

class App extends Component<{},{}>{
  render() {
    return (
        <Proveedor>
          <div className={style.base}>
            <BrowserRouter>
                <Rutas />
            </BrowserRouter>
          </div>
          </Proveedor>
    )
  }
}

render(<App />, document.getElementById('root'));
