import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useContext} from 'react';


import style from './style.scss';
import { LoginContext } from './../../contexto/index';
import Menu from '../../componentes/menu';
import ItemLista from '../../componentes/itemLista';
import { Cuota } from './../../datos';
import EditarCuota from '../../componentes/formularios/editarCuota';
import Modal from '../../componentes/modal';
import useDatabase from '../../hooks/useDatabase';



export default function CuotasVista(props:any){
    const login = useContext(LoginContext);
    const [togle,setTogle] = useState<boolean>(false);
    const [cuota,setCuota] = useState<any>({});
    function editar(c: Cuota){
        setCuota(c);
        setTogle(true);
    }
    function mostrarEditor(){
        if (togle){
            return <EditarCuota cuota={cuota} />;
        } else {
            return <div></div>
        }
    }
    if (login.logueado == false){
        return <Redirect to="/" />
    } else {
        return (
            <React.Fragment>
                <Menu />
                <div className={style.cuerpo}>
                    <div className={style.contenedor}>
                        <div className={style.fila}>
                            <h2>Lista Cuotas</h2>
                        </div>
                        <div className={style.lista}>
                            {
                                login.cuotas.map((cuota,key)=>{
                                    return <ItemLista item={{nombre:cuota.nombre,valor:"$"+cuota.valor}} key={key} editar={(e)=>editar(cuota)} />
                                })
                            }
                        </div>
                    </div>
                </div>
                <Modal togle={togle} setTogle={setTogle}>
                    {
                        mostrarEditor()
                    }
                </Modal>
            </React.Fragment>
        )
    }
}