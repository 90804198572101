import * as React from 'react';
import { useState, useContext} from 'react';


import style from './style.scss';
import { Cuota, Cliente } from './../../datos';
import useDatabase from '../../hooks/useDatabase';
import moment from 'moment';
import { LoginContext } from '../../contexto';

export default function FormPago(props:any){
    const datos = useContext(LoginContext);
    const [filtro,setFiltro] = useState<string>('');
    const [cliente,setCliente] = useState<string>('');
    const [cuota,setCuota] = useState<string>('');

    function cerrar(e){
        e.preventDefault();
        setFiltro('');
        setCliente('');
        setCuota('');
        props.cerrar();
    }
    function cargarPago(){
        let cuotaElegida = datos.cuotas.find((c)=>c.id == cuota);
        let mes = moment().add(cuotaElegida.cantidad, 'months').calendar();
        let fecha = mes.split('/');
        let clienteNuevo = datos.clientes.find((c)=>c.id == cliente);
        clienteNuevo.dayCuota = parseInt(fecha[1]);
        clienteNuevo.monthCuota = parseInt(fecha[0]);
        clienteNuevo.yearCuota = parseInt(fecha[2]);
        clienteNuevo.cuota = cuota;
        datos.editarCliente(clienteNuevo);
    }
    function editar(e){
        e.preventDefault();
        let fecha = new Date();
        let mes = fecha.getMonth();
        let filtrada = datos.cuotas.filter((c)=>c.id == cuota)[0];
        fecha.setMonth(mes + filtrada.cantidad);
        cargarPago();
        props.cerrar();
    }
    return (
        <div className={style.formulario}>
            <h2>Nueva Cuota</h2>
            <div className={style.fila}>
                <input type="text" placeholder="Buscar Clientes" value={filtro} onChange={(e)=> setFiltro(e.currentTarget.value)} />
                <button onClick={(e)=> datos.obtenerClientes(filtro)}>Buscar</button>
            </div>
            <div className={style.fila}>
                <select value={cliente} onChange={(e)=>setCliente(e.currentTarget.value)}>
                    <option value="">Elegir el cliente</option>
                    {
                        datos.clientes.map((cliente,key)=>{
                            return <option value={cliente.id} key={key}>{cliente.nombre} {cliente.apellido}</option>
                        })
                    }
                </select>
            </div>
            <div className={style.fila}>
                <select value={cuota} onChange={(e)=>setCuota(e.currentTarget.value)}>
                    <option value="">Elegir la cuota</option>
                    {
                        datos.cuotas.map((cuota,key)=>{
                            return <option value={cuota.id} key={key}>{cuota.nombre}</option>
                        })
                    }
                </select>
            </div>
            <div className={style.botonera}>
                <button onClick={editar}>Pagar</button>
                <button onClick={cerrar}>Cancelar</button>
            </div>
        </div>
    )
}