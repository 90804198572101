import React, {createContext} from 'react';
import { useState, useEffect } from 'react';
import firebase from '../firebase';
import useDatabase from '../hooks/useDatabase';

//en el context tomar clientes y clases y horarios y cuotas, y en tiempo real manejar los cupos de los horarios nomas.

export const LoginContext = createContext();

export function Proveedor(props:any){
    const [logueado,setLog] = useState<boolean>(false);
    const {clientes,vencidos,clases,cuotas,turnos,
        crearClase,crearCliente,crearCuota,crearTurno,
        editarClase,editarCliente,editarCuota,
        eliminarClase,eliminarCliente,eliminarCuota,eliminarTurno,
        obtenerClases,obtenerClientes,obtenerCuotas,obtenerTurnos,obtenerVencidos,leerTurno,
        consultarTurno,pedirTurno,pagarCliente} = useDatabase();
    function setLogeado(){
        setLog(true);
    }

    return (
        <LoginContext.Provider value={{logueado,setLogeado,clientes,vencidos,clases,cuotas,turnos,
            crearClase,crearCliente,crearCuota,crearTurno,
            editarClase,editarCliente,editarCuota,
            eliminarClase,eliminarCliente,eliminarCuota,eliminarTurno,
            obtenerClases,obtenerClientes,obtenerCuotas,obtenerTurnos,obtenerVencidos,leerTurno,
            consultarTurno,pedirTurno,pagarCliente}}>
            {props.children}
        </LoginContext.Provider>
    )
}