import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAImemffGL1kLt9yxlja30M9F-BMmnukaY",
  authDomain: "gymorganizador.firebaseapp.com",
  databaseURL: "https://gymorganizador.firebaseio.com",
  projectId: "gymorganizador",
  storageBucket: "gymorganizador.appspot.com",
  messagingSenderId: "687105037203",
  appId: "1:687105037203:web:bd60b6545b1c2576948e66",
  measurementId: "G-C388PJWNQ8"
};
firebase.initializeApp(firebaseConfig);
export default firebase;