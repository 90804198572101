import * as React from 'react';
import { useState, useEffect, useContext} from 'react';


import style from './style.scss';
import useDatabase from '../../hooks/useDatabase';
import { LoginContext } from '../../contexto';


export default function EditarCliente(props:any){
    const datos = useContext(LoginContext);
    const [nombre,setNombre] = useState<string>('');
    const [apellido,setApellido] = useState<string>('');
    const [telefono,setTelefono] = useState<string>('');
    const [diaCliente,setDiaCliente] = useState<number>(0);
    const [mesCliente,setMesCliente] = useState<number>(0);
    const [anoCliente,setAnoCliente] = useState<number>(0);
    const [primera,setPrimera] = useState<boolean>(true);

    useEffect(()=>{
        if (primera){
            setNombre(props.cliente.nombre);
            setTelefono(props.cliente.telefono);
            setApellido(props.cliente.apellido);
            setDiaCliente(props.cliente.dayCuota);
            setMesCliente(props.cliente.monthCuota);
            setAnoCliente(props.cliente.yearCuota);
            setPrimera(false);
        } 
    })
    function cerrar(e){
        e.preventDefault();
        setNombre('');
        setTelefono('');
        setApellido('');
        setDiaCliente(0);
        setMesCliente(0);
        setAnoCliente(0);
        props.cerrar();
    }
    function eliminar(e){
        e.preventDefault();
        datos.eliminarCliente(props.cliente.id);
        setNombre('');
        setTelefono('');
        props.cerrar();
    }
    
    function editar(e){
        e.preventDefault();
        datos.editarCliente({
            id:props.cliente.id,
            nombre:nombre,
            apellido:apellido,
            telefono:telefono,
            cuota: props.cliente.cuota,
            dayCuota: diaCliente,
            monthCuota: mesCliente,
            yearCuota: anoCliente,
            turno:props.cliente.turno
        });
        setNombre('');
        setTelefono('');
        setApellido('');
        setDiaCliente(0);
        setMesCliente(0);
        setAnoCliente(0);
        props.cerrar();
    }
    return (
        <div className={style.formulario}>
            <h2>Editar Cliente: {props.cliente.nombre}</h2>
            <div className={style.fila}>
                <input type="text" placeholder="nombre" value={nombre} onChange={(e)=> setNombre(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="apellido" value={apellido} onChange={(e)=> setApellido(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="Telefono con caracteristica sin 0 ni 15 ej: 2984214221" value={telefono} onChange={(e)=> setTelefono(e.currentTarget.value)}  />
            </div>
            <div className={style.fila}>
                <input type="number" value={diaCliente} onChange={(e)=> setDiaCliente(e.currentTarget.value)} max="31" min="1"  />
                <input type="number" value={mesCliente} onChange={(e)=> setMesCliente(e.currentTarget.value)} max="12" min="1" />
                <input type="number" value={anoCliente} onChange={(e)=> setAnoCliente(e.currentTarget.value)}  />
            </div>
            <div className={style.separador}></div>
            <div className={style.botonera}>
                <button onClick={editar}>Editar</button>
                <button onClick={cerrar}>Cancelar</button>
                <button onClick={eliminar}>Eliminar</button>
            </div>
        </div>
    )
}