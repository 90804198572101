import * as React from 'react';
import { Switch, Route } from "react-router-dom";
import Login from './vistas/login';
import Home from './vistas/home/index';
import ClientesVista from './vistas/clientesVista/index';
import CuotasVista from './vistas/cuotasVista';
import CronogramaVista from './vistas/cronogramaVista/index';

// REHACER LA PAGINA DE ACA DESDE 0 PARA TERMINAR EL MARTES SI O SI.

export function Rutas(props:any){
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/inicio" component={Home} />
            <Route exact path="/clientes" component={ClientesVista} />
            <Route exact path="/cuotas" component={CuotasVista} />
            <Route exact path="/clases" component={CronogramaVista} />
        </Switch>
    );
}