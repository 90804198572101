import * as React from 'react';
import { useState, useContext} from 'react';
import moment from 'moment';


import style from './style.scss';
import useDatabase from '../../hooks/useDatabase';
import { LoginContext } from '../../contexto';


export default function FormCliente(props:any){
    const datos = useContext(LoginContext);
    const [primera,setPrimera] = useState<boolean>(true);
    const [nombre,setNombre] = useState<string>('');
    const [apellido,setApellido] = useState<string>('');
    const [telefono,setTelefono] = useState<string>('');
    const [cuota,setCuota] = useState<string>('');
    React.useEffect(()=>{
        if (primera){
            datos.obtenerCuotas();
            setPrimera(false);
        }
    })
    function cerrar(e){
        e.preventDefault();
        setNombre('');
        setCuota('');
        setTelefono('');
        props.cerrar();
    }
    function editar(e){
        e.preventDefault();
        let cuotaElegida = datos.cuotas.find((c)=>c.id == cuota);
        let mes = moment().add(cuotaElegida.cantidad, 'months').calendar();
        let fecha = mes.split('/');
        datos.crearCliente({nombre:nombre,apellido:apellido,telefono:telefono,cuota:cuota,dayCuota:parseInt(fecha[1]),monthCuota:parseInt(fecha[0]),yearCuota:parseInt(fecha[2]),turno:''});
        props.cerrar();
    }
    return (
        <div className={style.formulario}>
            <h2>Nuevo Cliente</h2>
            <div className={style.fila}>
                <input type="text" placeholder="nombre" value={nombre} onChange={(e)=> setNombre(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="apellido" value={apellido} onChange={(e)=> setApellido(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="Telefono con caracteristica sin 0 ni 15 ej: 2984214221" value={telefono} onChange={(e)=> setTelefono(e.currentTarget.value)}  />
            </div>
            <div className={style.fila}>
                <select value={cuota} onChange={(e)=> setCuota(e.currentTarget.value)} >
                    <option value=''>Elegir Cuota</option>
                    {datos.cuotas.map((cuota,key)=>{
                        return <option key={key} value={cuota.id}>{cuota.nombre}</option>
                    })}
                </select>
            </div>
            <div className={style.botonera}>
                <button onClick={editar}>Agregar</button>
                <button onClick={cerrar}>Cancelar</button>
            </div>
        </div>
    )
}