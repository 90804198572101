import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useContext} from 'react';


import style from './style.scss';
import { LoginContext } from './../../contexto/index';
import Menu from '../../componentes/menu';
import { Cliente } from '../../datos';
import ItemLista from '../../componentes/itemLista';
import EditarCliente from './../../componentes/formularios/editarCliente';
import Modal from '../../componentes/modal';
import useDatabase from '../../hooks/useDatabase';



export default function ClientesVista(props:any){
    const login = useContext(LoginContext);
    const [filtro,setFiltro] = useState<string>('');
    const [togle,setTogle] = useState<boolean>(false);
    const [cliente,setCliente] = useState<any>({});
    function editar(c: Cliente){
        setCliente(c);
        setTogle(true);
    }
    function mostrarEditor(){
        if (togle){
            return <EditarCliente cliente={cliente} />
        } else {
            return <div></div>
        }
    }
    if (login.logueado == false){
        return <Redirect to="/" />
    } else {
        return (
            <React.Fragment>
                <Menu />
                <div className={style.cuerpo}>
                    <div className={style.contenedor}>
                        <div className={style.fila}>
                            <input type="text" placeholder="Buscar Cliente" value={filtro} onChange={(e)=> setFiltro(e.currentTarget.value)} />
                            <button onClick={(e)=>login.obtenerClientes(filtro)}>Buscar</button>
                        </div>
                        <div className={style.lista}>
                            {
                                login.clientes.map((cliente:any,key:number)=>{
                                    return <ItemLista key={key} item={{nombre:cliente.nombre+' '+cliente.apellido,telefono:cliente.telefono}} editar={(e)=>editar(cliente)} />
                                })
                            }
                        </div>
                    </div>
                </div>
                <Modal togle={togle} setTogle={setTogle}>
                    {
                        mostrarEditor()
                    }
                </Modal>
            </React.Fragment>
        )
    }
}