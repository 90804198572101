import * as React from 'react';
import { useState, useEffect,useContext} from 'react';


import style from './style.scss';
import useDatabase from '../../hooks/useDatabase';
import { LoginContext } from '../../contexto';


export default function EditarCuota(props:any){
    const datos = useContext(LoginContext);
    const [nombre,setNombre] = useState<string>('');
    const [duracion,setDuracion] = useState<string>('');
    const [valor,setValor] = useState<string>('');
    const [primera,setPrimera] = useState<boolean>(true);
    
    useEffect(()=>{
        if (primera){
            setNombre(props.cuota.nombre);
            setValor(props.cuota.valor);
            setDuracion(props.cuota.cantidad);
            setPrimera(false);
        } 
    })
    function cerrar(e){
        e.preventDefault();
        setNombre('');
        setValor('');
        setDuracion('');
        props.cerrar();
    }
    function editar(e){
        e.preventDefault();
        datos.editarCuota({id:props.cuota.id,nombre:nombre,valor:parseInt(valor),cantidad:parseInt(duracion)});
        props.cerrar();
    }
    function eliminar(e){
        e.preventDefault();
        datos.eliminarCuota(props.cuota.id);
        props.cerrar();
    }
    return (
        <div className={style.formulario}>
            <h2>Editar Cuota: {props.cuota.nombre}</h2>
            <div className={style.fila}>
                <p>Nombre </p><input type="text" placeholder="Nombre de la cuota." value={nombre} onChange={(e)=> setNombre(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <p>Duracion </p><input type="text" placeholder="duracion" value={duracion} onChange={(e)=> setDuracion(e.currentTarget.value)}  />
            </div>
            <div className={style.fila}>
                <p>Valor </p><input type="text" placeholder="valor" value={valor} onChange={(e)=> setValor(e.currentTarget.value)}  />
            </div>
            <div className={style.botonera}>
                <button onClick={editar}>Editar</button>
                <button onClick={cerrar}>Cancelar</button>
                <button onClick={eliminar}>Eliminar</button>
            </div>
        </div>
    )
}
