import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import { userInfo } from 'os';

interface Cliente {
    id?: string;
    nombre: string;
    apellido: string;
    telefono: string;
    cuota: string;
    dayCuota: number;
    monthCuota: number;
    yearCuota: number;
    turno:string
}
interface Cuota {
    id?: string;
    nombre: string;
    valor: number;
    cantidad: number;
}
interface Clase {
    id?:string;
    nombre: string;
    cupo: number;
    lunes: Array<number>;
    martes: Array<number>;
    miercoles: Array<number>;
    jueves: Array<number>;
    viernes: Array<number>;
    sabado: Array<number>;
    domingo: Array<number>;
}
interface Turno {
    cliente: string;
    clase: string;
    dia: string;
    dayTurno: number;
    monthTurno: number;
    yearTurno: number;
    horaTurno: number;
}
export default function useDatabase(){
    const db = firebase.firestore();
    const [clientes,setClientes] = useState<Array<Cliente>>([]);
    const [vencidos,setVencidos] = useState<Array<Cliente>>([]);
    const [clases, setClases] = useState<Array<Clase>>([]);
    const [turnos,setTurnos] = useState<Array<Turno>>([]);
    const [cuotas,setCuotas] = useState<Array<Cuota>>([]);
    const [primera,setPrimera] = useState<boolean>(true);
    
    useEffect(()=>{
        if (primera){
            obtenerTurnos();
            obtenerVencidos();
            obtenerCuotas();
            obtenerClases();
            setPrimera(false);
        }
    })
    function crearCliente(dato:Cliente){
        db.collection('clientes').add({
            nombre: dato.nombre,
            apellido: dato.apellido,
            telefono: dato.telefono,
            cuota: dato.cuota,
            dayCuota: dato.dayCuota,
            monthCuota: dato.monthCuota,
            yearCuota: dato.yearCuota,
            turno: dato.turno,
        });
    }
    function obtenerVencidos(){
        let base = new Date();
        let base2 = new Date();
        let diaActual = (base.getDay() != 0? base.getDay() : 7);
        let mesActual = base.getMonth();
        let anoActual = base.getFullYear();
        db.collection('clientes').where('dayCuota','<=',diaActual).where('monthCuota',"==",mesActual).where('yearCuota',"==",anoActual).onSnapshot((snap)=>{
            let lista: Array<Cliente> = [];
            snap.forEach((cliente)=>{
                lista.push({
                    id: cliente.id,
                    nombre: cliente.data().nombre,
                    apellido: cliente.data().apellido,
                    telefono: cliente.data().telefono,
                    cuota: cliente.data().cuota,
                    dayCuota: cliente.data().dayCuota,
                    monthCuota: cliente.data().monthCuota,
                    yearCuota: cliente.data().yearCuota,
                    turno: cliente.data().turno,
                })
            })
            setVencidos(lista);
        });
    }
    function obtenerClientes(filtro:string){
        let lista: Array<Cliente> = [];
        db.collection('clientes').where('nombre','==',filtro).onSnapshot((snap)=>{
            snap.forEach((cliente)=>{
                lista.push({
                    id: cliente.id,
                    nombre: cliente.data().nombre,
                    apellido: cliente.data().apellido,
                    telefono: cliente.data().telefono,
                    cuota: cliente.data().cuota,
                    dayCuota: cliente.data().dayCuota,
                    monthCuota: cliente.data().monthCuota,
                    yearCuota: cliente.data().yearCuota,
                    turno: cliente.data().turno,
                })
            })
        });
        db.collection('clientes').where('telefono','==',filtro).onSnapshot((snap)=>{
            snap.forEach((cliente)=>{
                lista.push({
                    id: cliente.id,
                    nombre: cliente.data().nombre,
                    apellido: cliente.data().apellido,
                    telefono: cliente.data().telefono,
                    cuota: cliente.data().cuota,
                    dayCuota: cliente.data().dayCuota,
                    monthCuota: cliente.data().monthCuota,
                    yearCuota: cliente.data().yearCuota,
                    turno: cliente.data().turno,
                })
            })
            setClientes(lista);
        });
    }
    function eliminarCliente(id:string){
        db.collection('clientes').doc(id).delete();
    }
    function editarCliente(dato:Cliente){
        db.collection('clientes').doc(dato.id).update({
            nombre: dato.nombre,
            apellido: dato.apellido,
            telefono: dato.telefono,
            cuota: dato.cuota,
            dayCuota: dato.dayCuota,
            monthCuota: dato.monthCuota,
            yearCuota: dato.yearCuota,
            turno: dato.turno,
        });
        setClientes([]);
    }
    function crearClase(dato:Clase){
        db.collection('clases').add({
            nombre: dato.nombre,
            cupo: dato.cupo,
            lunes: dato.lunes,
            martes: dato.martes,
            miercoles: dato.miercoles,
            jueves: dato.jueves,
            viernes: dato.viernes,
            sabado: dato.sabado,
            domingo: dato.domingo
        });
    }
    function eliminarClase(id:string){
        db.collection('clases').doc(id).delete();
    }
    function editarClase(dato:Clase){
        db.collection('clases').doc(dato.id).update({
            nombre: dato.nombre,
            cupo: dato.cupo,
            lunes: dato.lunes,
            martes: dato.martes,
            miercoles: dato.miercoles,
            jueves: dato.jueves,
            viernes: dato.viernes,
            sabado: dato.sabado,
            domingo: dato.domingo
        })
    }
    function obtenerClases(){
        db.collection('clases').onSnapshot((snap)=>{
            let lista: Array<Clase> = [];
            snap.forEach((clase)=>{
                lista.push({
                    id:clase.id,
                    nombre:clase.data().nombre,
                    cupo:clase.data().cupo,
                    lunes: clase.data().lunes,
                    martes: clase.data().martes,
                    miercoles: clase.data().miercoles,
                    jueves: clase.data().jueves,
                    viernes: clase.data().viernes,
                    sabado: clase.data().sabado,
                    domingo: clase.data().domingo
                });
            });
            setClases(lista);
        })
    }
    function crearCuota(nuevo:Cuota){
        db.collection('cuotas').add({
            nombre: nuevo.nombre,
            valor: nuevo.valor,
            cantidad: nuevo.cantidad
        });
    }
    function obtenerCuotas(){
        db.collection('cuotas').onSnapshot((snap)=>{
            let lista: Array<Cuota> = [];
            snap.forEach((cuota)=>{
                lista.push({
                    id: cuota.id,
                    nombre: cuota.data().nombre,
                    valor: cuota.data().valor,
                    cantidad: cuota.data().cantidad
                })
            })
            setCuotas(lista);
        })
    }
    function eliminarCuota(id:string){
        db.collection('cuotas').doc(id).delete();
    }
    function editarCuota(nuevo:Cuota){
        db.collection('cuotas').doc(nuevo.id).update({
            nombre: nuevo.nombre,
            valor: nuevo.valor,
            cantidad: nuevo.cantidad
        });
    }
    function crearTurno(nuevo:Turno){
        db.collection('turnos').add({
            cliente: nuevo.cliente,
            clase: nuevo.clase,
            dia: nuevo.dia,
            dayTurno: nuevo.dayTurno,
            monthTurno: nuevo.monthTurno,
            yearTurno: nuevo.yearTurno,
            horaTurno: nuevo.horaTurno
        })
    }
    function eliminarTurno(id:string){
        db.collection('turnos').doc(id).delete();
    }
    function obtenerTurnos(){
        let base = new Date();
        let diaActual = base.getDate();
        let mesActual = base.getMonth();
        let anoActual= base.getFullYear();
        db.collection('turnos').where('dayTurno',"==",diaActual).where('monthTurno','==',mesActual).where('yearTurno','==',anoActual).onSnapshot((snap)=>{
            let diaActual: Array<any> = [];
            snap.forEach((turno)=>{
                diaActual.push({
                    id: turno.id,
                    cliente: turno.data().cliente,
                    clase: turno.data().clase,
                    dia: turno.data().dia,
                    dayTurno: turno.data().dayTurno,
                    monthTurno: turno.data().monthTurno,
                    yearTurno: turno.data().yearTurno,
                    horaTurno: turno.data().horaTurno
                })
            })
            setTurnos(diaActual);
        });
    }
    function consultarTurno(cupo:number,turno:Turno){
        let lista: Array<any> = [];
        turnos.forEach((t)=>{
            if (t.clase == turno.clase){
                lista.push(turno.clase);
            }
        })
        if (lista.length < cupo){
            return true;
        } else {
            return false;
        }
    }
    function pedirTurno(turno:Turno){
        db.collection('turnos').add({
            cliente: turno.cliente,
            clase: turno.clase,
            dia: turno.dia,
            dayTurno: turno.dayTurno,
            monthTurno: turno.monthTurno,
            yearTurno: turno.yearTurno,
            horaTurno: turno.horaTurno
        }).then(()=>{
            db.collection('clientes').doc(turno.cliente).update({
                turno: turno
            })
        })
    }
    function pagarCliente(cliente:string,cuota:string,dia:number,mes:number,ano:number){
        db.collection('clientes').doc(cliente).update({
            cuota: cuota,
            dayCuota: dia,
            monthCuota: mes,
            yearCuota: ano
        })
    }
    
    return {
        clientes,vencidos,clases,cuotas,turnos,
        crearClase,crearCliente,crearCuota,crearTurno,
        editarClase,editarCliente,editarCuota,
        eliminarClase,eliminarCliente,eliminarCuota,eliminarTurno,
        obtenerClases,obtenerClientes,obtenerCuotas,obtenerTurnos,obtenerVencidos,
        consultarTurno,pedirTurno,pagarCliente
    }
}


