import * as React from 'react';
import { useState, useEffect, useContext } from 'react';


import style from './style.scss';
import useDatabase from '../../hooks/useDatabase';
import { LoginContext } from './../../contexto/index';


export default function EditarClase(props:any){
    const datos = useContext(LoginContext);
    const [nombre,setNombre] = useState<string>('');
    const [cupo,setCupo] = useState<string>('');
    const [primera,setPrimera] = useState<boolean>(true);
    const [lunes,setLunes] = useState<Array<number>>([]);
    const [martes,setMartes] = useState<Array<number>>([]);
    const [miercoles,setMiercoles] = useState<Array<number>>([]);
    const [jueves,setJueves] = useState<Array<number>>([]);
    const [viernes,setViernes] = useState<Array<number>>([]);
    const [sabado,setSabado] = useState<Array<number>>([]);
    const [domingo,setDomingo] = useState<Array<number>>([]);
    const [dia,setDia] = useState<number>(0);
    const diasNombres = ['lunes','martes','miercoles','jueves','viernes','sabado','domingo'];
    const horarios = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
    useEffect(()=>{
        if (primera){
            setNombre(props.clase.nombre);
            setCupo(props.clase.cupo);
            setLunes(props.clase.lunes);
            setMartes(props.clase.martes);
            setMiercoles(props.clase.miercoles);
            setJueves(props.clase.jueves);
            setViernes(props.clase.viernes);
            setSabado(props.clase.sabado);
            setDomingo(props.clase.domingo);
            setPrimera(false);
        } 
    })
    function cerrar(e){
        e.preventDefault();
        setNombre('');
        setCupo('');
        setNombre('');
        setCupo('');
        setLunes([]);
        setMartes([]);
        setMiercoles([]);
        setJueves([]);
        setViernes([]);
        setSabado([]);
        setDomingo([]);
        props.cerrar();
    }
    function editar(e){
        e.preventDefault();
        let dias: Array<any> = [];
        datos.editarClase({id:props.clase.id,nombre:nombre,cupo:parseInt(cupo),lunes:lunes,martes:martes,miercoles:miercoles,jueves:jueves,viernes:viernes,sabado:sabado,domingo:domingo});
        props.cerrar();
    }
    function eliminar(e){
        e.preventDefault();
        let dias: Array<any> = [];
        datos.eliminarClase(props.clase.id);
        props.cerrar();
    }
    function cambiarValor(hora:number){
        let lista: Array<any> = [];
        switch(dia){
            case 1:
                lista = lunes;
                if (lista.includes(hora).valueOf()){
                    lista.splice(lista.findIndex((h)=> h == hora),1)
                } else {
                    lista.push(hora);
                }
                setLunes(lista);
                break;
            case 2:
                lista = martes;
                if (lista.includes(hora).valueOf()){
                    lista.splice(lista.findIndex((h)=> h == hora),1)
                } else {
                    lista.push(hora);
                }
                setMartes(lista);
                break;
            case 3:
                lista = miercoles;
                if (lista.includes(hora).valueOf()){
                    lista.splice(lista.findIndex((h)=> h == hora),1)
                } else {
                    lista.push(hora);
                }
                setMiercoles(lista);
                break;
            case 4:
                lista = jueves;
                if (lista.includes(hora).valueOf()){
                    lista.splice(lista.findIndex((h)=> h == hora),1)
                } else {
                    lista.push(hora);
                }
                setJueves(lista);
                break;
            case 5:
                lista = viernes;
                if (lista.includes(hora).valueOf()){
                    lista.splice(lista.findIndex((h)=> h == hora),1)
                } else {
                    lista.push(hora);
                }
                setViernes(lista);
                break;
            case 6:
                lista = sabado;
                if (lista.includes(hora).valueOf()){
                    lista.splice(lista.findIndex((h)=> h == hora),1)
                } else {
                    lista.push(hora);
                }
                setSabado(lista);
                break;
            case 7:
                lista = domingo;
                if (lista.includes(hora).valueOf()){
                    lista.splice(lista.findIndex((h)=> h == hora),1)
                } else {
                    lista.push(hora);
                }
                setDomingo(lista);
                break;
        }
        
    }
    
    function cargarDia(){
        let horario = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
        switch(dia){
            case 1:
                return (
                    <React.Fragment>
                        {
                            horario.map((hora,key)=>{
                                return (
                                    <BotonHorario key={key} dia={lunes} hora={hora} cambiarValor={cambiarValor} />
                                )
                            })
                        }
                    </React.Fragment>
                )
                break;
            case 2:
                return (
                    <React.Fragment>
                        {
                            horario.map((hora,key)=>{
                                return (
                                    <BotonHorario key={key} dia={martes} hora={hora} cambiarValor={cambiarValor} />
                                )
                            })
                        }
                    </React.Fragment>
                )
                break;
            case 3:
                return (
                    <React.Fragment>
                        {
                            horario.map((hora,key)=>{
                                return (
                                    <BotonHorario key={key} dia={miercoles} hora={hora} cambiarValor={cambiarValor} />
                                )
                            })
                        }
                    </React.Fragment>
                )
                break;
            case 4:
                return (
                    <React.Fragment>
                        {
                            horario.map((hora,key)=>{
                                return (
                                    <BotonHorario key={key} dia={jueves} hora={hora} cambiarValor={cambiarValor} />
                                )
                            })
                        }
                    </React.Fragment>
                )
                break;
            case 5:
                return (
                    <React.Fragment>
                        {
                            horario.map((hora,key)=>{
                                return (
                                    <BotonHorario key={key} dia={viernes} hora={hora} cambiarValor={cambiarValor} />
                                )
                            })
                        }
                    </React.Fragment>
                )
                break;
            case 6:
                return (
                    <React.Fragment>
                        {
                            horario.map((hora,key)=>{
                                return (
                                    <BotonHorario key={key} dia={sabado} hora={hora} cambiarValor={cambiarValor} />
                                )
                            })
                        }
                    </React.Fragment>
                )
                break;
            case 7:
                return (
                    <React.Fragment>
                        {
                            horario.map((hora,key)=>{
                                return (
                                    <BotonHorario key={key} dia={domingo} hora={hora} cambiarValor={cambiarValor} />
                                )
                            })
                        }
                    </React.Fragment>
                )
                break;
        }
    }
    return (
        <div className={style.formulario}>
            <h2>Editar Clase: {props.clase.nombre}</h2>
            <div className={style.fila}>
                <input type="text" placeholder="Nombre de la clase." value={nombre} onChange={(e)=> setNombre(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="Cupo" value={cupo} onChange={(e)=> setCupo(e.currentTarget.value)}  />
            </div>
            <div className={style.fila}>
                <select value={dia} onChange={(e)=>setDia(parseInt(e.target.value))}>
                    <option value="0">Elegir un dia</option>
                    {
                        diasNombres.map((dia,key)=>{
                            return <option key={key} value={key+1}>{dia}</option>
                        })
                    }
                </select>
            </div>
            <div className={style.scrolleable}>
                {
                    (dia != 0? cargarDia() : '')
                }
            </div>
            <div className={style.botonera}>
                <button onClick={editar}>Editar</button>
                <button onClick={eliminar}>Eliminar</button>
                <button onClick={cerrar}>Cancelar</button>
            </div>
        </div>
    )
}


//usar botones para el horario y cambien segun el valor;
function BotonHorario(props:any){
    const [mensaje,setMensaje]=useState<string>('Inactivo');
    const [primera,setPrimera] = useState<boolean>(true);
    const [cual,setCual] = useState<boolean>(true);
    //cargar el valor inicial con primera y despues con los clicks que alteren otro booleano y este cambie el texto
    useEffect(()=>{
        if (primera){
            if (props.dia.includes(props.hora).valueOf()){
                setCual(true);
            } else {
                setCual(false);
            }
            setPrimera(false);
        }
        if (cual){
            setMensaje('Activo');
        }
        if (!cual) {
            setMensaje('Inactivo');
        }
    })
    function cambio(e){
        e.preventDefault();
        setCual(valor => valor = !cual);
        props.cambiarValor(props.hora);
    }
    return (
        <div className={style.fila}>
        <button onClick={(e)=>cambio(e)} className={style.botonLista}>
            {(props.hora < 10? `0${props.hora}:00` : `${props.hora}:00`)} - {mensaje}
        </button>
    </div>
    )
}