import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useState, useEffect} from 'react';

import style from './style.scss';

//prop togle para tomar la variable y el setTogle para mandar al hijo el cerrar.

export default function Modal(props:any){
    return (
        <div className={(props.togle? style.modal : style.hidden)}>
            {React.cloneElement(props.children, { cerrar:props.setTogle })}
        </div>
    );
}