import * as React from 'react';
import { useState, useContext} from 'react';


import style from './style.scss';
import useDatabase from '../../hooks/useDatabase';
import { LoginContext } from '../../contexto';


export default function FormCuota(props:any){
    const datos = useContext(LoginContext);
    const [nombre,setNombre] = useState<string>('');
    const [valor,setValor] = useState<string>('');
    const [duracion,setDuracion] = useState<string>('');

    function cerrar(e){
        e.preventDefault();
        setNombre('');
        setValor('');
        setDuracion('');
        props.cerrar();
    }
    function editar(e){
        e.preventDefault();
        datos.crearCuota({nombre:nombre,cantidad:parseInt(duracion),valor:parseInt(valor)});
        props.cerrar();
    }
    return (
        <div className={style.formulario}>
            <h2>Nueva Cuota</h2>
            <div className={style.fila}>
                <input type="text" placeholder="Nombre de la cuota." value={nombre} onChange={(e)=> setNombre(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="valor de la cuota." value={valor} onChange={(e)=> setValor(e.currentTarget.value)}  />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="Duracion en meses de la cuota." value={duracion} onChange={(e)=> setDuracion(e.currentTarget.value)}  />
            </div>
            <div className={style.botonera}>
                <button onClick={editar}>Agregar</button>
                <button onClick={cerrar}>Cancelar</button>
            </div>
        </div>
    )
}