import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useEffect, useContext} from 'react';


import style from './style.scss';
import { LoginContext } from './../../contexto/index';
import Menu from '../../componentes/menu';
import Lista from './../../componentes/lista/index';
import ItemSinBotones from '../../componentes/itemSinBotones';
import Modal from '../../componentes/modal';
import FormCliente from './../../componentes/formularios/formCliente';
import ItemDeudores from '../../componentes/itemDeudores';
import FormClase from './../../componentes/formularios/formClase';
import FormCuota from './../../componentes/formularios/formCuota';
import FormPago from './../../componentes/formularios/formPago';
import PagoCliente from './../../componentes/formularios/pagoCliente';
import { Cliente } from '../../datos';



export default function Home(props:any){
    const login = useContext(LoginContext);
    const [togle,setTogle] = useState<boolean>(false);
    const [tipo,setTipo] = useState<string>('cliente');
    const [cliente,setCliente] = useState<any>({});
    function queModal(){
        if (tipo == 'cliente'){
            return <Modal togle={togle} setTogle={setTogle}><FormCliente /></Modal>
        }
        if (tipo == 'pago'){
            return <Modal togle={togle} setTogle={setTogle}><FormPago /></Modal>
        }
        if (tipo == 'cuota'){
            return <Modal togle={togle} setTogle={setTogle}><FormCuota /></Modal>
        }
        if (tipo == 'clase'){
            return <Modal togle={togle} setTogle={setTogle}><FormClase /></Modal>
        }
        if (tipo == 'pagoDirecto'){
            if (togle){
                return <Modal togle={togle} setTogle={setTogle}><PagoCliente cliente={cliente} /></Modal>
            } else {
                return <div></div>
            }
        }
    }
    function obtenerCupo(hora: number){
        let hoy = new Date();
        let numeroHoy = hoy.getDay();
        let dia = [];
        let cupo = 0;
        login.clases.forEach((clase)=>{
            switch(numeroHoy){
                case 0:
                    if (clase.domingo.includes(hora).valueOf()){
                        cupo += clase.cupo;
                    }
                    break;
                case 1:
                    if (clase.lunes.includes(hora).valueOf()){
                        cupo += clase.cupo;
                    }
                    break;
                case 2:
                    if (clase.martes.includes(hora).valueOf()){
                        cupo += clase.cupo;
                    }
                    break;
                case 3:
                    if (clase.miercoles.includes(hora).valueOf()){
                        cupo += clase.cupo;
                    }
                    break;
                case 4:
                    if (clase.jueves.includes(hora).valueOf()){
                        cupo += clase.cupo;
                    }
                    break;
                case 5:
                    if (clase.viernes.includes(hora).valueOf()){
                        cupo += clase.cupo;
                    }
                    break;
                case 6:
                    if (clase.sabado.includes(hora).valueOf()){
                        cupo += clase.cupo;
                    }
                    break;
            }
        })
        return cupo;
    }
    function dibujarHorarios(){
        let horarios: Array<any>= [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
        return horarios.map((horario,key)=>{
            let turnosActuales = [];
            login.turnos.forEach((turno)=>{
                if (turno.horaTurno == horario){
                    turnosActuales.push(turno);
                }
            })
            if (horario != undefined){
                    return <ItemSinBotones item={{hora:(horario < 10? `0${horario}:00` : `${horario}:00`), cupo:`${turnosActuales.length}/${obtenerCupo(horario)}`}} key={key} />
            }
        })
    }
    function abrirModal(tipo:string,c?:Cliente){
        if (c != null || c != undefined){
            setCliente(c);
        }
        setTipo(tipo);
        setTogle(true);
    }


    if (login.logueado == false){
        return <Redirect to="/" />
    } else {
        return (
            <React.Fragment>
                <Menu />
                <div className={style.cuerpo}>
                    <div className={style.botonera}>
                        <button onClick={(e)=>abrirModal('cliente')}>Agregar Cliente</button>
                        <button onClick={(e)=>abrirModal('pago')}>Registrar Pago</button>
                        <button onClick={(e)=>abrirModal('cuota')}>Agregar Cuota</button>
                        <button onClick={(e)=>abrirModal('clase')}>Agregar Clase</button>
                    </div>
                    <div className={style.listas}>
                        <div className={style.lista}>
                            <Lista titulo="Turnos de Hoy" titulos={['Hora','Cupo']}>
                                {
                                    dibujarHorarios()
                                }
                            </Lista>
                        </div>
                        <div className={style.lista}>
                            <Lista titulo="Cuotas Vencidas" titulos={['Nombre','Fecha','pagar']}>
                                {
                                    login.vencidos.map((deudor,key)=>{
                                        return <ItemDeudores item={{nombre:deudor.nombre+' '+deudor.apellido,fecha:deudor.dayCuota+"/"+deudor.monthCuota+"/"+deudor.yearCuota}} key={key} pagar={(e)=>abrirModal('pagoDirecto',deudor)} />
                                    })
                                }
                            </Lista>
                        </div>
                    </div>
                </div>
                {queModal()}
            </React.Fragment>
        )
    }
}