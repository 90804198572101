import * as React from 'react';
import { useState, useContext} from 'react';


import style from './style.scss';
import useDatabase from '../../hooks/useDatabase';
import { LoginContext } from '../../contexto';


export default function FormClase(props:any){
    const datos = useContext(LoginContext);
    const [nombre,setNombre] = useState<string>('');
    const [cupo,setCupo] = useState<string>('');
    function cerrar(e){
        e.preventDefault();
        setNombre('');
        setCupo('');
        props.cerrar();
    }
    function editar(e){
        e.preventDefault();
        datos.crearClase({nombre:nombre,cupo:parseInt(cupo),lunes:[],martes:[],miercoles:[],jueves:[],viernes:[],sabado:[],domingo:[]});
        props.cerrar();
    }
    return (
        <div className={style.formulario}>
            <h2>Nueva Clase</h2>
            <div className={style.fila}>
                <input type="text" placeholder="Nombre de la clase." value={nombre} onChange={(e)=> setNombre(e.currentTarget.value)} />
            </div>
            <div className={style.fila}>
                <input type="text" placeholder="Cupo" value={cupo} onChange={(e)=> setCupo(e.currentTarget.value)}  />
            </div>
            <div className={style.botonera}>
                <button onClick={editar}>Agregar</button>
                <button onClick={cerrar}>Cancelar</button>
            </div>
        </div>
    )
}