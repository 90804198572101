import * as React from 'react';
import { useState, useContext} from 'react';


import style from './style.scss';


export default function ItemLista(props:any){
    function borrar(e){
        e.preventDefault();
        props.borrar();
    }
    function editar(e){
        e.preventDefault();
        props.editar();
    }
    return (
        <div className={style.item}>
            {
                Object.values(props.item).map((dato:any,key:number)=>{
                    return <div className={style.texto} key={key}>{dato}</div>
                })
            }
            <div className={style.botonera}>
                <button onClick={editar}>Editar</button>
            </div>
        </div>
    )
}