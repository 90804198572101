import * as React from 'react';
import { useState, useEffect,useContext} from 'react';


import style from './style.scss';
import { Cuota, Cliente } from './../../datos';
import useDatabase from '../../hooks/useDatabase';
import moment from 'moment';
import { LoginContext } from '../../contexto';


export default function PagoCliente(props:any){
    const datos = useContext(LoginContext);
    const [cliente,setCliente] = useState<string>('');
    const [cuota,setCuota] = useState<string>('');
    const [primera,setPrimera] = useState<boolean>(true);

    useEffect(()=>{
        if (primera){
            setCliente(props.cliente.id);
            setCuota(props.cliente.cuota);
            setPrimera(false);
        } 
    })
    function cerrar(e){
        e.preventDefault();
        setCuota('');
        props.cerrar();
    }
    function editar(e){
        e.preventDefault();
        let cuotaElegida = datos.cuotas.find((c)=>c.id == cuota);
        let mes = moment().add(cuotaElegida.cantidad, 'months').calendar();
        let fecha = mes.split('/');
        let diaCuota = parseInt(fecha[1]);
        let mesCuota = parseInt(fecha[0]);
        let anoCuota = parseInt(fecha[2]);
        datos.pagarCliente(cliente,cuota,diaCuota,mesCuota,anoCuota);
        props.cerrar();
    }
    return (
        <div className={style.formulario}>
            <h2>Pago para: {props.cliente.nombre}</h2>
            <div className={style.fila}>
                <select value={cuota} onChange={(e)=>setCuota(e.currentTarget.value)}>
                    <option value="">Elegir la cuota</option>
                    {
                        datos.cuotas.map((cuota,key)=>{
                            return <option value={cuota.id} key={key}>{cuota.nombre}</option>
                        })
                    }
                </select>
            </div>
            <div className={style.botonera}>
                <button onClick={editar}>Pagar</button>
                <button onClick={cerrar}>Cancelar</button>
            </div>
        </div>
    )
}