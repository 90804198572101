import * as React from 'react';
import { useState, useContext} from 'react';


import style from './style.scss';


export default function Lista(props:any){
    return (
        <div className={style.lista}>
            <div className={style.titular}><h3>{props.titulo}</h3></div>
            <div className={style.cabecera}>
                {
                    props.titulos.map((titulo:string,key:number)=>{
                        return <h4 key={key}>{titulo}</h4>
                    })
                }
            </div>
            <div className={style.cuerpoLista}>
                { props.children }
            </div>
        </div>
    )
}